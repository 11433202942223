.WrapperForm {
    position: relative;
    height: 100%;
    .allRequests {
        font-size: 13px;
        margin-bottom: 10px;
    }

    a {
        color: var(--main-blue-color);
    }
    form {
        padding: 13px 16px;
        height: 100%;
        overflow: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
    .wInput {
        margin-bottom: 10px;
        .label {
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
            margin-bottom: 5px;
            sup {
                color: #FF4C4C;
            }
        }
        input, select {
            height: 48px;
            width: 100%;
            outline: none;
            border: 1px solid #EAEEF2;
            background: #F1F5F9;
            border-radius: 10px;
            padding: 0 16px;
            font-family: 'Inter';
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
        }

        textarea {
            width: 100%;
            outline: none;
            border: 1px solid #EAEEF2;
            background: #F1F5F9;
            border-radius: 10px;
            padding: 5px 10px;
            resize: none;
            min-height: 96px;
        }
    }

    .btn {
        border-radius: 8px;
        height: 48px;
        margin-top: auto;
        background: var(--main-blue-color);
        color: #fff;
        font-size: 14px;
        &:disabled {
            opacity: 0.5;
            pointer-events: none;
        }
    }

    .sent {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 15px;
        height: 100%;
        flex-direction: column;
        gap: 20px;
        p {
            margin: 0;
            padding: 0;
            font-size: 22px;
            text-align: center;
        }
    }
}
