.Wrapper {
    position: relative;
    width: 360px;
    height: 664px;
    border-radius: 16px;
    box-shadow: 0px 4px 32px 0px #DCE0E5;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #fff;
}

