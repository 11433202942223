.OpenButton {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
}
