.Header {
    padding: 16px;
    border-bottom: 1px solid var(--main-border-color);
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;

    button {
        width: 14px;
        height: 14px;
        svg {
            width: 14px;
            height: 14px;
            stroke: var(--regular-text-color);
        }
    }

    .WrapperActions {
        display: flex;
        align-items: center;
        gap: 10px;
    }
}
