.wrapperDropFile {
    .dropFile {
        width: 100%;
        height: 94px;
        border-radius: 8px;
        border: 1px dashed #EAEEF2;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        transition: all 0.3s;
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.36px;

        .fileName {
            color: var(--regular-text-color);
        }
        .textInfo {
            .info {
                display: flex;
                align-items: center;
            }
            .additionalInfo {
                color: #ADB8C3;
                padding-top: 2px;
                text-align: center;
            }
            p {
                margin: 0;
            }
            button {
                margin-left: 4px;
                background: transparent;
                padding: 0;
                outline: none;
                cursor: pointer;
                box-shadow: none;
                border: 0;
                color: var(--regular-text-color);
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                line-height: 1;
                letter-spacing: -0.36px;
                text-decoration-line: underline;
            }
        }
    }

}
